@charset "UTF-8";

/*==============================================================================================

レイアウト設定

==============================================================================================*/

//PC タブレットPC向け サイト全体幅
$width_wrap: 960px;

//スマートフォン向け サイト全体 余白
$mobile_wrap_padding: 0 10px;

//PC タブレットPC向け メインコンテント幅
$main_content_width: 690px;

//PC タブレットPC向け サイドバー幅
$sidebar_width: 250px;

//メインコンテントの位置 左ならleft 右ならright
$main_content_position: right;

//サイドバーの位置 左ならleft 右ならright
$sidebar_position: left;




//============================================================================================

// メディアクエリ短縮形

//============================================================================================

//メディアクエリ短縮形 スマートフォン用
@mixin mq-mobile{
	@media screen and(max-width:$break-point-mobile){
		@content;
	}
}

//メディアクエリ短縮形 PC タブレットPC用
@mixin mq-pc{
	@media screen and(min-width:$break-point-pc){
		@content;
	}
}

/*スマートフォン用レイアウト設定*/
@include mq-mobile{
	.wrap {
//		padding: $mobile_wrap_padding;
	}
}

/*PC タブレットPC用レイアウト設定*/
@include mq-pc{

	.wrap {
		width: $width_wrap;
		margin : 0 auto;
		position: relative;
	}

	//2カラムレイアウトのメインコンテントの位置設定
	.main_content_position {
		width: $main_content_width;
		float: $main_content_position;
	}

	//2カラムレイアウトのサイドバーの位置設定
	.sidebar_position {
		width: $sidebar_width;
		float: $sidebar_position;
	}

}
