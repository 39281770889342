@charset "UTF-8";

/*============================================================================================

細かな共通スタイル設定

============================================================================================*/

.text_left{
	text-align: left;
}

.text_right{
	text-align: right;
}

.text_center{
	text-align: center;
}

.font_small{
	font-size: small;
}

.none{
	display:none;
}


//============================================================================================

// ボタン

//==============================================================================================

@mixin button{
	background: $primary_color;
	padding:5px;
	border-radius: 4px;
}



//============================================================================================

// シャドウ

//=============================================================================================

//ボックスシャドウグレイ
@mixin box_shadow_gray{
	box-shadow: 0px 6px 9px -7px rgba(89, 89, 89, 0.6);
}

//ボックスシャドウ ホワイト
@mixin box_shadow_white{
	box-shadow:0px 6px 9px -7px rgba(255, 255, 255, 1);
}

//テキストシャドウ グレイ
@mixin text_shadow_gray{
	text-shadow:0 1px 0 rgba(89, 89, 89, 0.6);
}

//テキストシャドウ ホワイト
@mixin text_shadow_white{
	text-shadow:0 1px 0 rgba(255, 255, 255, 1);
}

//テキストシャドウ ブラック
@mixin text_shadow_black{
	text-shadow:1px 2px 1px rgba(0, 0, 0, 1);
}


//============================================================================================

// 画像置換URL関数

//============================================================================================

@function bg_url($fileName){
	$img_directory:'images/';
	@return url($img_directory + $fileName) + no-repeat;
}

/* 記述例

#header-nav{

	@include mq-pc{

		li{
			@include hide-text_mix;
		}

		a{
			display: block;
			height: ***px;
		}

		.menu__***{
			background: bg_url('***.png');
			width: ***px;
		}

	}

}

*/

/*============================================================================================

float解除

==============================================================================================*/

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.clear{
	clear: both;
}

@mixin clearfix_mix{
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}



/*============================================================================================

画像置換

==============================================================================================*/

//MediaQueryに関わらず画像置換
.hide_text{
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

// レスポンシブで指定ウィンドウ幅以上の時
.hide_text--pc{

	@include mq-pc{
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
	}

}

@mixin hide_text_mix{
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}



/*============================================================================================

ブロック定義

==============================================================================================*/

@mixin block__left{
	box-sizing: border-box;
	float: left;
}

@mixin block__right{
	box-sizing: border-box;
	float: right;
}

@include mq-pc{

	.block__center{
		box-sizing: border-box;
		margin: 0 auto;
	}

	.block__left--w20{
		@include block__left;
		width: 20%;
	}

	.block__left--w30{
		@include block__left;
		width: 30%;
	}

	.block__left--w40{
		@include block__left;
		width: 40%;
	}

	.block__left--w50{
		@include block__left;
		width: 50%;
	}

	.block__left--w60{
		@include block__left;
		width: 60%;
	}

	.block__left--w70{
		@include block__left;
		width: 70%;
	}

	.block__left--w80{
		@include block__left;
		width: 80%;
	}

	.block__right--w20{
		@include block__right;
		width: 20%;
	}

	.block__right--w30{
		@include block__right;
		width: 30%;
	}

	.block__right--w40{
		@include block__right;
		width: 40%;
	}

	.rblock__right--w50{
		@include block__right;
		width: 50%;
	}

	.block__right--w60{
		@include block__right;
		width: 60%;
	}

	.block__right--w70{
		@include block__right;
		width: 70%;
	}

	.block__right--w80{
		@include block__right;
		width: 80%;
	}

}



/*============================================================================================

960grid
//コンテナとして使う

==============================================================================================*/

//未定義
//2, 3, 4, 5, 6, 8, 10, 12, 15, 16, 20, 24, 30, 32, 40, 48, 60, 64, 80, 96,

@mixin grid_padding{
	box-sizing: border-box;
	padding: 5px;
}

//8カラム
.grid_120{
	width: 120px;
	@include grid_padding;
}

//6カラム
.grid_160{
	width: 160px;
	@include grid_padding;
}

//5カラム
.grid_192{
	width: 192px;
	@include grid_padding;
}

//4カラム
.grid_240{
	width: 240px;
	@include grid_padding;
}

//3カラム
.grid_320{
	width: 320px;
	@include grid_padding;
}

//2カラム
.grid_480{
	width: 480px;
	@include grid_padding;
}

//1カラム
.grid_960{
	width: 960px;
	@include grid_padding;
}



/*============================================================================================

フルードイメージ レスポンシブ用画像伸縮

============================================================================================*/

.fluid_img{
	max-width: 100%;
	width: auto;
	height: auto;
}

@mixin fluid_img_mix{
	max-width: 100%;
	width: auto;
	height: auto;
}


/*============================================================================================

PDFアイコン表示

============================================================================================*/

a[href $='.pdf'] {
	background:url(images/icon-pdf.png) no-repeat left center;
	padding: 25px 10px 25px 40px ;
}

a[href $='.pdf'] a {
	color:#0000FF;
}

a[href $='.pdf'] a:hover{
	color:#0000FF;
	text-decoration:underline;
}


//============================================================================================

// 並行定義リストスタイル

//============================================================================================

@mixin parallel_dt_dd($dt_w,$dd_ml) {
	dt{
		float:left;
		width: $dt_w;
	}
	dd{
		margin: 0;
		padding-left: $dd_ml;
	}
}

// 記述例
//.hentry{
//	@include parallel_dt_dd(5em,8em);
//}