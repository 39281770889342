@charset "UTF-8";

//============================================================================================

// config.scss

//============================================================================================


//============================================================================================

// ブレイクポイント設定

//============================================================================================

//スマートフォン用
$break-point-mobile: 640px;

//PC タブレットPC用
$break-point-pc: 641px;



//============================================================================================

// カラー設定
// ページ全体で使う色を設定する

//============================================================================================

//テキストカラー 本文用
$text_color: #FFFFFF;

//リンクカラー
$link_color: #FF4A81;

//プライマリカラー
$primary_color: #000000;

//セカンダリカラー
$secondary_color: #CCCCCC;


//============================================================================================
//
// フォント設定
// ページ全体で使う文字を設定する
//
//============================================================================================

//フォントサイズ
$font-size_initialize: 62.5%; //フォントサイズの初期設定
$font-size_old_ie: 14px; //IE8以下用のフォントサイズ設定
$font-size: 1.4rem; // =14px

//行の高さ
//$line-height: 1.6;
$line-height: 1.8;

//文字の間隔
$letter-spacing: 0.15em;

// フォントファミリを変数にセット
$font-family-mincyo: 'Hiragino Mincho Pro','ヒラギノ明朝 Pro W6','HGS明朝E','MS P明朝',serif;
$font-family-kakugo: 'Lucida','Grande','Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, sans-serif;
$font-family-marugo: 'ヒラギノ丸ゴ Pro','Hiragino Maru Gothic Pro', Meiryo, メイリオ, sans-serif;

//フォントファミリを指定
$font-family: $font-family-marugo;

//フォント強調色
$font-color-strong: #997D10;