@charset "UTF-8";
/*
Theme Name: aisai_theme
Theme URI: http://b-faith.com/
Description:
Version: 1.0
Author: o2 Group
Author URI: http://b-faith.com/
Tags: simple
*/
/*============================================================================================

@import

============================================================================================*/
/*==============================================================================================

レイアウト設定

==============================================================================================*/
/*スマートフォン用レイアウト設定*/
/*PC タブレットPC用レイアウト設定*/
@media screen and (min-width: 641px) {
  .wrap {
    width: 960px;
    margin: 0 auto;
    position: relative;
  }

  .main_content_position {
    width: 690px;
    float: right;
  }

  .sidebar_position {
    width: 250px;
    float: left;
  }
}
/*============================================================================================

細かな共通スタイル設定

============================================================================================*/
.text_left {
  text-align: left;
}

.text_right {
  text-align: right;
}

.text_center {
  text-align: center;
}

.font_small {
  font-size: small;
}

.none {
  display: none;
}

/* 記述例

#header-nav{

	@include mq-pc{

		li{
			@include hide-text_mix;
		}

		a{
			display: block;
			height: ***px;
		}

		.menu__***{
			background: bg_url('***.png');
			width: ***px;
		}

	}

}

*/
/*============================================================================================

float解除

==============================================================================================*/
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.clear {
  clear: both;
}

/*============================================================================================

画像置換

==============================================================================================*/
.hide_text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (min-width: 641px) {
  .hide_text--pc {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
}

/*============================================================================================

ブロック定義

==============================================================================================*/
@media screen and (min-width: 641px) {
  .block__center {
    box-sizing: border-box;
    margin: 0 auto;
  }

  .block__left--w20 {
    box-sizing: border-box;
    float: left;
    width: 20%;
  }

  .block__left--w30 {
    box-sizing: border-box;
    float: left;
    width: 30%;
  }

  .block__left--w40 {
    box-sizing: border-box;
    float: left;
    width: 40%;
  }

  .block__left--w50 {
    box-sizing: border-box;
    float: left;
    width: 50%;
  }

  .block__left--w60 {
    box-sizing: border-box;
    float: left;
    width: 60%;
  }

  .block__left--w70 {
    box-sizing: border-box;
    float: left;
    width: 70%;
  }

  .block__left--w80 {
    box-sizing: border-box;
    float: left;
    width: 80%;
  }

  .block__right--w20 {
    box-sizing: border-box;
    float: right;
    width: 20%;
  }

  .block__right--w30 {
    box-sizing: border-box;
    float: right;
    width: 30%;
  }

  .block__right--w40 {
    box-sizing: border-box;
    float: right;
    width: 40%;
  }

  .rblock__right--w50 {
    box-sizing: border-box;
    float: right;
    width: 50%;
  }

  .block__right--w60 {
    box-sizing: border-box;
    float: right;
    width: 60%;
  }

  .block__right--w70 {
    box-sizing: border-box;
    float: right;
    width: 70%;
  }

  .block__right--w80 {
    box-sizing: border-box;
    float: right;
    width: 80%;
  }
}
/*============================================================================================

960grid
//コンテナとして使う

==============================================================================================*/
.grid_120 {
  width: 120px;
  box-sizing: border-box;
  padding: 5px;
}

.grid_160 {
  width: 160px;
  box-sizing: border-box;
  padding: 5px;
}

.grid_192 {
  width: 192px;
  box-sizing: border-box;
  padding: 5px;
}

.grid_240 {
  width: 240px;
  box-sizing: border-box;
  padding: 5px;
}

.grid_320 {
  width: 320px;
  box-sizing: border-box;
  padding: 5px;
}

.grid_480 {
  width: 480px;
  box-sizing: border-box;
  padding: 5px;
}

.grid_960 {
  width: 960px;
  box-sizing: border-box;
  padding: 5px;
}

/*============================================================================================

フルードイメージ レスポンシブ用画像伸縮

============================================================================================*/
.fluid_img {
  max-width: 100%;
  width: auto;
  height: auto;
}

/*============================================================================================

PDFアイコン表示

============================================================================================*/
a[href$='.pdf'] {
  background: url(images/icon-pdf.png) no-repeat left center;
  padding: 25px 10px 25px 40px;
}

a[href$='.pdf'] a {
  color: #0000FF;
}

a[href$='.pdf'] a:hover {
  color: #0000FF;
  text-decoration: underline;
}

/*============================================================================================

common

============================================================================================*/
* {
  margin: 0;
  padding: 0;
}

html {
  background: #000000;
  font-size: 62.5%;
}

body {
  font-family: "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", Meiryo, メイリオ, sans-serif;
  font-size: 1.4rem;
  color: #FFFFFF;
  line-height: 1.8;
  letter-spacing: 0.15em;
  word-wrap: break-word;
  overflow-wrap: break-word;
  background: #000000;
}

a {
  color: #FF4A81;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  opacity: 0.7;
}

a:active, a:focus {
  outline: 0;
}

img {
  border: 0;
}

.clear {
  clear: both;
}

p {
  margin-bottom: 10px;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

li {
  list-style: none;
}

a:hover img {
  opacity: 0.7;
}

input[type="text"], input[type="password"], textarea {
  border: 1px solid #EBEBEB;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.none {
  display: none;
}

@media screen and (max-width: 640px) {
  .mobile_none {
    display: none;
  }
}

@media screen and (min-width: 641px) {
  .pc_none {
    display: none;
  }
}

/*============================================================================================

btn

============================================================================================*/
.btn_wrap {
  text-align: center;
  background: #FF4A81;
  margin: 5px 10px;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}
.btn_wrap a {
  color: #FFFFFF;
}

.btn_wrap input[type="submit"] {
  display: block;
  color: #FFFFFF;
  border: none;
  background: transparent;
  padding: 10px;
  text-align: center;
  width: 100%;
  cursor: pointer;
}

/*============================================================================================

headline

============================================================================================*/
.headline--big, .headline--medium,
.headline--min,
.headline--large {
  font-weight: normal;
  font-family: "Hiragino Mincho Pro", "ヒラギノ明朝 Pro W6", "HGS明朝E", "MS P明朝", serif;
  padding: 20px 10px 10px 40px;
}
@media screen and (max-width: 640px) {
  .headline--big, .headline--medium,
  .headline--min,
  .headline--large {
    padding-top: 15px;
    border-radius: 4px;
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 641px) {
  .headline--big, .headline--medium,
  .headline--min,
  .headline--large {
    display: table;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    font-size: 1.8rem;
    padding-top: 10px;
  }
}
.headline--big span, .headline--medium span,
.headline--min span,
.headline--large span {
  text-shadow: 0 1px 0 rgba(89, 89, 89, 0.6);
  vertical-align: middle;
  display: table-cell;
  color: #FFFFFF;
}

.headline--big, .headline--medium,
.headline--min,
.headline--large {
  background: url(images/h2_bg.png) no-repeat;
}

@media screen and (min-width: 641px) {
  .headline--medium,
  .headline--min {
    background: url(images/side_h3.png);
    display: table;
    width: 100%;
    height: 50px;
  }
}

.headline--small {
  margin-bottom: 10px;
  font-size: 120%;
  color: #FF7AB1;
  border-left: 2px solid #FF7AB1;
  border-bottom: 1px dotted #FFD0E4;
  padding: 0 10px 10px 10px;
}

/*******************************
/* ヘッダー
*******************************/
#header {
  position: relative;
  color: #FFFFFF;
  font-weight: normal;
  padding: 5px;
}
@media screen and (max-width: 640px) {
  #header {
    text-align: left;
    padding: 1rem;
  }
}
@media screen and (min-width: 641px) {
  #header {
    background: url(images/header_bg.png) top center no-repeat;
  }
}
#header .header_title {
  font-size: 1.4rem;
  padding: 5px;
  margin: 10px 0;
}
@media screen and (max-width: 640px) {
  #header .header_title {
    margin-right: 100px;
  }
}
@media screen and (min-width: 641px) {
  #header .header_title {
    text-align: center;
    margin-bottom: 20px;
  }
}

/*******************************
/* ヘッダーイメージ
*******************************/
.header_img--title {
  text-align: center;
}

.header_img--copy {
  text-align: center;
}

@media screen and (min-width: 641px) {
  .header_img--data {
    position: absolute;
    top: 190px;
    right: 0;
  }
}

/******************
/* ヘッダーナビゲーション
******************/
/************************************
/* メインナビゲーション
************************************/
#main_nav {
  background: url(images/nav_bg_on.png) repeat-x center;
}
#main_nav ul li {
  float: left;
}
#main_nav img {
  vertical-align: bottom;
}

/*==============================================================================================

hentry

==============================================================================================*/
.hentry .size-thumbnail,
.hentry .size-medium,
.hentry .size-large,
.hentry .size-full {
  max-width: 100%;
  width: auto;
  /* Prevent stretching of full-size and large-size images with height and width attributes in IE8 */
  height: auto;
  /* Make sure images with WordPress-added height and width attributes are scaled correctly */
}
.hentry .aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 641px) {
  .hentry .alignleft {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    max-width: 100%;
  }
  .hentry .alignright {
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
    max-width: 100%;
  }
}
@media screen and (min-width: 641px) {
  .hentry p, .hentry ul, .hentry ol, .hentry dl, .hentry ul, .hentry blockquote, .hentry table, .hentry hr {
    margin: 0 0 20px 0;
  }
}

.hentry + .hentry {
  margin-top: 40px;
}

.hentry_header {
  margin-bottom: 10px;
}

@media screen and (min-width: 641px) {
  .hentry_thumbnail {
    margin-right: 10px;
    float: left;
  }
}

@media screen and (min-width: 641px) {
  .page .hentry_thumbnail,
  .single .hentry_thumbnail {
    margin-right: 0;
    float: none;
  }
}

.hentry * + h1, .hentry * + h2 {
  margin-top: 40px;
}
.hentry * + h3, .hentry * + h4, .hentry * + h5, .hentry * + h6 {
  margin-top: 20px;
}
.hentry h2 {
  text-shadow: 1px 2px 1px black;
  background: url("images/bg_sub--light.png");
  font-weight: normal;
  padding: 10px;
}
@media screen and (max-width: 640px) {
  .hentry h2 {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 641px) {
  .hentry h2 {
    padding: 30px 40px;
  }
}
@media screen and (min-width: 641px) {
  .hentry h2 + .min-content {
    border-left: 1px solid #34322B;
    border-right: 1px solid #34322B;
    border-bottom: 1px solid #34322B;
    padding: 40px 40px 20px 40px;
    margin-bottom: 50px;
  }
}
.hentry .min-content--pad {
  padding: 0 !important;
  border: none !important;
}
.hentry dl.panel {
  font-size: 1.8rem;
  margin-bottom: 10px;
}
@media screen and (max-width: 640px) {
  .hentry dl.panel {
    border-radius: 4px;
  }
}
.hentry dl.panel dt, .hentry dl.panel dd {
  text-align: center;
}
.hentry dl.panel dt {
  background: #FFFFFF;
  color: #FF4A81;
}
.hentry dl.panel dd {
  background: #FF4A81;
  color: #FFFFFF;
}
@media screen and (min-width: 641px) {
  .hentry dl.panel:after {
    content: "";
    display: block;
    clear: both;
  }
  .hentry dl.panel dt, .hentry dl.panel dd {
    width: 24%;
    float: left;
    box-sizing: border-box;
    margin-bottom: 1%;
    padding: 10px;
    display: inline-block;
  }
  .hentry dl.panel dt {
    font-size: 1.6rem;
    border-right: none;
  }
  .hentry dl.panel dd {
    font-size: 1.6rem;
    border-left: none;
    margin-right: 1%;
  }
}
.hentry ul.panel:after {
  content: "";
  display: block;
  clear: both;
}
.hentry ul.panel li {
  text-align: center;
  background: url(images/bg_ptn.png);
  border-radius: 4px;
  padding: 2%;
  color: #EF1693;
  width: 50%;
  float: left;
  margin-bottom: 1%;
  box-sizing: border-box;
}
.hentry ul.panel li:nth-child(odd) {
  width: 49%;
  margin-right: 1%;
}
.hentry ul.panel li:nth-child(even) {
  width: 49%;
  margin-left: 1%;
}
@media screen and (min-width: 641px) {
  .hentry ul.panel {
    font-size: 2rem;
  }
}

.hentry .tittle--big, .hentry .btn--link {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #F42B95;
  border-left: 2px solid #F42B95;
  padding-left: 10px;
}

.hentry .btn--link {
  text-shadow: 0 1px 0 rgba(89, 89, 89, 0.6);
  text-align: center;
  font-size: 2.2rem;
  background: #FF3788;
  border: none;
  border-radius: 8px;
  color: #FFFFFF;
  padding: 10px;
}
@media screen and (min-width: 641px) {
  .hentry .btn--link {
    margin: 20px 20% 20px 20% !important;
  }
}
.hentry .btn--link a {
  display: block;
  color: #FFFFFF;
}

.hentry strong {
  font-size: 140%;
  line-height: 1.6;
  color: #D60C76;
}

.hentry .strong_wrap {
  background: url(images/bg_ptn--stripe.png) #F4F4F2;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
}
.hentry .strong_wrap strong {
  display: block;
  text-align: center;
}

.hentry .member_qr,
.hentry .login_qr {
  border: 1px solid #FEE4ED;
  padding: 20px;
  margin-bottom: 10px;
  background: url("images/bg_ptn--stripe.png") #E8E8E8;
}
.hentry .member_qr:after,
.hentry .login_qr:after {
  content: "";
  display: block;
  clear: both;
}
@media screen and (min-width: 641px) {
  .hentry .member_qr,
  .hentry .login_qr {
    display: table;
  }
}
.hentry .member_qr .member_qr--title,
.hentry .member_qr .login_qr--title,
.hentry .login_qr .member_qr--title,
.hentry .login_qr .login_qr--title {
  text-shadow: 0 1px 0 white;
  text-align: center;
  padding: 10px;
  font-size: 2rem;
  margin-bottom: 5px;
  display: block;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (min-width: 641px) {
  .hentry .member_qr .member_qr--title,
  .hentry .member_qr .login_qr--title,
  .hentry .login_qr .member_qr--title,
  .hentry .login_qr .login_qr--title {
    font-size: 2.6rem;
  }
}
.hentry .member_qr .member_qr--title,
.hentry .login_qr .member_qr--title {
  border: 5px solid #62B39A;
  color: #62B39A;
}
.hentry .member_qr .login_qr--title,
.hentry .login_qr .login_qr--title {
  border: 5px solid #45C5CF;
  color: #45C5CF;
}
.hentry .member_qr dt,
.hentry .login_qr dt {
  color: #341617;
}
@media screen and (min-width: 641px) {
  .hentry .member_qr dt,
  .hentry .login_qr dt {
    display: table-cell;
    width: 60%;
  }
}
.hentry .member_qr dd,
.hentry .login_qr dd {
  text-align: center;
}
@media screen and (min-width: 641px) {
  .hentry .member_qr dd,
  .hentry .login_qr dd {
    display: table-cell;
    width: 40%;
    vertical-align: middle;
  }
  .hentry .member_qr dd img,
  .hentry .login_qr dd img {
    margin: 0 0 0 10px !important;
  }
}

.hentry .caution--list li {
  font-size: 120%;
  margin-bottom: 10px;
  padding: 10px;
}
.hentry .caution--list li:nth-child(even) {
  background: #262626;
}

.hentry .pickup {
  text-shadow: 0 1px 0 white;
  text-align: center;
  background: url(images/bg_ptn.png);
  border-radius: 4px;
  padding: 2%;
  color: #EF1693;
  margin-bottom: 1%;
  box-sizing: border-box;
  font-size: 120%;
}

.hentry .new_cast, .hentry .now_attend {
  background: #F7558D;
  color: #FFFFFF;
  padding: 2px 10px;
  border-radius: 8px;
}

.hentry .now_attend {
  background: #D650D6;
}

/*******************************
メイン画像
*******************************/
/*******************************
コンテンツ位置
*******************************/
#container {
  margin-top: 40px;
}

@media screen and (max-width: 640px) {
  #main {
    padding: 1%;
  }
}

/*************************
/* topページ
*************************/
.top_content {
  margin: 5px auto 30px auto;
}

.base_block, .cast_content, .blog_list {
  margin-top: 40px;
  margin-bottom: 40px;
}

.blog_list {
  margin-bottom: 30px;
}

.search_form {
  width: 705px;
  padding: 5px 5px 5px 6px;
  margin-top: 14px;
  margin-bottom: 30px;
}

.a_cast {
  float: left;
}
@media screen and (max-width: 640px) {
  .a_cast {
    width: 48%;
    margin: 1%;
    text-align: center;
  }
  .a_cast:nth-child(odd) {
    clear: both;
  }
}
@media screen and (min-width: 641px) {
  .a_cast {
    width: 200px;
    margin: 15px;
  }
  .a_cast:nth-child(3n+1) {
    clear: both;
  }
}

.a_cast_fig img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.a_cast_comment {
  text-align: center;
  position: relative;
}

@media screen and (min-width: 641px) {
  .like_list {
    position: absolute;
    z-index: 10;
    top: -100px;
    left: 80px;
  }
}

.like_list li a {
  display: block;
  background: url(images/iine_comment.png) no-repeat 0 50% #FF4A81;
  margin-bottom: 3px;
  color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #FFFFFF;
  padding-left: 35px;
}
@media screen and (min-width: 641px) {
  .like_list li a {
    line-height: 28px;
    height: 28px;
    width: 93px;
    text-align: left;
    font-size: 0.8em;
  }
}

.blog_list ul {
  margin: 5px;
}

.blog_list ul li {
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px dotted #CCCCCC;
}

.blog_list ul li:last-child {
  border: none;
}

.blog_list ul li a {
  display: block;
  margin-bottom: 8px;
  font-size: 1.8rem;
}

.blog_list ul li a:hover {
  text-decoration: underline;
}

/*************************
/* スケジュールページ
*************************/
.like_list2 li {
  /*	display:block;
  	background:url(images/iine_comment.png) no-repeat 0 50%;
  	padding-left:35px;
  	line-height:28px;
  	height:28px;
  	width:93px;
  	text-align:left;
  	font-size:0.8em;
  	margin-bottom:3px;
  	margin-right:6px;
  	color:#630;
  	float:left;
  	overflow:hidden;*/
  background: #FF4A81;
  padding: 5px;
  box-sizing: border-box;
  float: left;
  border-radius: 12px;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .like_list2 li {
    width: 48%;
    margin: 1%;
  }
}
@media screen and (min-width: 641px) {
  .like_list2 li {
    width: 23%;
    margin: 1%;
  }
}
.like_list2 li a {
  color: #FFFFFF;
  display: block;
  font-size: 90%;
}

@media screen and (min-width: 641px) {
  .like_list2 li:nth-child(4n+1) {
    clear: both;
  }
}

/*************************
/* 奥様たちページ
*************************/
.a_cast2 {
  float: left;
  margin: 5px 5px 20px 5px;
}

.a_cast_fig2 {
  overflow: hidden;
  width: 165px;
  height: 248px;
}

.a_cast_fig2 img {
  display: block;
}

.a_cast_comment2 {
  text-align: center;
  position: relative;
}

.like_list3 {
  position: absolute;
  z-index: 10;
  top: -93px;
  left: 50px;
}

.like_list3 li a {
  display: block;
  background: url(images/iine_comment.png) no-repeat 0 50%;
  padding-left: 35px;
  line-height: 28px;
  height: 28px;
  width: 93px;
  text-align: left;
  font-size: 0.8em;
  margin-bottom: 3px;
  color: #630;
}

.a_cast_comment2 img {
  padding-top: 4px;
}

/*************************
/* single-cast.php
*************************/
/*============================================================================================

cast

============================================================================================*/
@media screen and (min-width: 641px) {
  #cast_fig {
    float: right;
    width: 35%;
  }
}

@media screen and (max-width: 640px) {
  #main_fig {
    text-align: center;
  }
}
#main_fig img {
  border: 1px solid #FF7AB1;
}

@media screen and (min-width: 641px) {
  .cast_txt {
    float: left;
    width: 60%;
  }
}

#mini_thm ul li {
  overflow: hidden;
  width: 70px;
  height: 90px;
  margin: 5px 5px 5px 0px;
  float: left;
}

.cast_profile--name {
  font-size: 3rem;
  color: #FF4A81;
  border-bottom: 2px dotted #A1A1A1;
  margin-bottom: 10px;
}
.cast_profile--name span {
  font-size: 70%;
}
@media screen and (max-width: 640px) {
  .cast_profile--name {
    text-align: center;
  }
}

.cast_profile_tl {
  width: 100%;
}
.cast_profile_tl th, .cast_profile_tl td {
  margin-bottom: 5px;
  line-height: 1.8;
  padding: 8px;
}
.cast_profile_tl th {
  color: #FF4A81;
  text-align: center;
  background: #0F0F0F;
  font-weight: normal;
  clear: both;
}
.cast_profile_tl td {
  background: #2B2B2B;
}

/*.font_min{
	font-size: 90%;
	letter-spacing: 0.1em;
}*/
/*.like_list4 {
	position:absolute;
	z-index:10;
	top:45px;
	left:-138px;
}*/
/*.like_list4 li a {
	display:block;
	background:url(images/iine_comment.png) no-repeat 0 50%;
	padding-left:35px;
	line-height:28px;
	height:28px;
	width:93px;
	text-align:left;
	font-size:0.8em;
	margin-bottom:3px;
	color:#630;
}*/
#cast_profile02 dl dt {
  /*	width:100px;
  	height:20px;
  	border-radius:10px;
  	border:1px #CCC solid;
  	line-height:20px;
  	text-align:center;*/
}

#cast_profile02 dl dd {
  /*	width:200px;
  	margin-left:100px;
  	margin-top:-20px;
  	border-bottom:1px #CCC dotted;
  	padding-left:20px;
  	margin-bottom:5px;*/
}

#enable_play {
  margin-bottom: 20px;
}
#enable_play dl {
  margin-bottom: 2px;
  box-sizing: border-box;
}
@media screen and (max-width: 640px) {
  #enable_play dl:after {
    content: "";
    display: block;
    clear: both;
  }
}
#enable_play dl:nth-child(even) {
  color: #EA704D;
}
@media screen and (min-width: 641px) {
  #enable_play dl:nth-child(even) {
    border-left: 1px solid #000000;
  }
}
#enable_play dl:nth-child(odd) {
  color: #18B8BC;
}
@media screen and (min-width: 641px) {
  #enable_play dl:nth-child(odd) {
    border-right: 1px solid #000000;
  }
}
#enable_play dl dt, #enable_play dl dd {
  padding: 5px;
  box-sizing: border-box;
}
#enable_play dl dt {
  float: left;
  width: 75%;
  text-align: center;
  background: #0F0F0F;
}
#enable_play dl dd {
  float: left;
  width: 25%;
  text-align: center;
  background: #2B2B2B;
}
@media screen and (min-width: 641px) {
  #enable_play dl {
    width: 50%;
    float: left;
  }
  #enable_play dl dd {
    width: 25%;
  }
}

/*	width:420px;
	height:25px;
	padding-left:10px;
	border-bottom:1px #630 solid;
	border-left:10px #630 solid;
	line-height:25px;*/
.cast_profile_comment {
  border: 3px solid #FF7AB1;
  background: url("images/bg_ptn.png");
  margin-bottom: 20px;
}

.cast_profile_comment--title {
  background: #FF7AB1;
  color: #FFFFFF;
  padding: 10px;
  font-size: 2rem;
}

.cast_profile_comment--body {
  text-shadow: 0 1px 0 white;
  padding: 20px;
  color: #FF7AB1;
}

.tencyo_comment {
  border: 1px solid #34322B;
  padding: 20px;
  margin-bottom: 20px;
}

.shopstaff_comment img {
  padding-right: 10px;
  float: left;
  display: block;
}

.shopstaff_comment {
  /*	width:640px;
  	padding:25px;
  	border:1px #FFF solid;
  	border-radius:12px;
  	box-shadow:#D2C797 5px 5px 10px;
  	margin:10px auto;
  	min-height:62px;*/
}

/*************************
/* page-blog.php
*************************/
/*============================================================================================

blog

============================================================================================*/
.blog_title, .blog_content {
  padding: 20px;
  border: 1px solid #34322B;
}
@media screen and (min-width: 641px) {
  .blog_title, .blog_content {
    padding: 20px 40px;
  }
}

.blog_title:first-child {
  margin-top: 60px;
}

.blog_title {
  text-shadow: 0 1px 0 white;
  position: relative;
  background: url("images/bg_ptn--stripe.png") #E0E0E0;
  border-bottom: none;
  color: #FF4A81;
  font-size: 2.2rem;
}

.blog_title--date {
  background: #FF4A81;
  color: #FFFFFF;
  position: absolute;
  top: -20px;
  right: 10px;
  font-size: 1.4rem;
  padding: 5px;
  line-height: 1.4;
  text-shadow: none;
  border-radius: 4px;
}

.blog_content {
  border-top: none;
  margin-bottom: 50px;
}

.paginate_div {
  text-align: center;
}

/*************************
/* page.php
*************************/
.page_title {
  width: 696px;
  height: 38px;
  line-height: 38px;
  padding-left: 20px;
  margin-bottom: 15px;
  text-indent: -9999px;
}

#index_system {
  background: url(images/index_system.png) no-repeat 0 50%;
}

#index_magazine {
  background: url(images/index_magazine.png) no-repeat 0 50%;
}

#index_recruit {
  background: url(images/index_recruit.png) no-repeat 0 50%;
}

#index_inquiry {
  background: url(images/index_inquiry.png) no-repeat 0 50%;
}

#index_caution {
  background: url(images/index_caution.png) no-repeat 0 50%;
}

#index_feature {
  background: url(images/index_feature.png) no-repeat 0 50%;
}

#index_404 {
  background: url(images/index_404.png) no-repeat 0 50%;
}

.page_div2 {
  /*	width:685px;
  	padding:14px;
  	background:#fff1d7;
  	border:1px solid #e6d7a8;
  	margin-top:10px;
  	margin-bottom:30px;
  	line-height:1.8em;
  	background:#fcfff4 url(images/div_back.png) no-repeat 0 50% !important;*/
}

#page_tmp_content {
  padding: 20px 0;
}

#page_tmp_content .caution {
  width: 600px;
  margin: 20px auto;
  display: block;
}

#recruit_child_link {
  width: 710px !important;
  margin: 0 auto 0px auto !important;
  height: 45px;
}

#recruit_child_link li {
  background: none !important;
  padding: 0 !important;
  overflow: hidden;
  float: left;
}

#recruit_child_link li a {
  width: 140px !important;
  background: url(images/recruit_icon.png) #ECE8D2 left 50% no-repeat !important;
  display: block;
  border-radius: 6px;
  border: 1px #FFF solid;
  color: #4F0000;
  font-weight: bold;
  text-shadow: #D5CC99 1px 1px;
  text-align: center;
  height: 45px;
  line-height: 45px;
}

.cast_gravure ul li {
  overflow: hidden;
  float: left;
  width: 120px;
  height: 155px;
  border-radius: 6px;
  border: 2px #FFF solid;
  margin: 5px;
}

/*************************
/* その他
*************************/
#date {
  text-align: right;
  padding-right: 5px;
  padding-top: 15px;
  padding-bottom: 10px;
  font-weight: bold;
  color: #858585;
  margin-top: -50px;
}

#date2 {
  text-align: right;
  /*	padding-right: 5px;
  	padding-top: 15px;
  	padding-bottom: 10px;
  	font-weight:bold;
  	color:#858585;
  	margin-top:-40px;*/
}

#next {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img.alignright {
  padding: 4px;
  margin: 0 0 2px 7px;
  display: inline;
}

img.alignleft {
  padding: 4px;
  margin: 0 7px 2px 0;
  display: inline;
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}

.box_img_border {
  border: 1px solid #cccccc;
  padding: 1px;
  margin: 1px;
}

/*************
/* 見出し
*************/
/*.indextype01 {
	width:696px;
	height:38px;
	line-height:38px;
	background:url(images/index_back01.png) 0 50% no-repeat;
	font-size: 1.3em;
	color: #6a3906;
	font-family: "ヒラギノ明朝 Pro W6", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", serif;
	padding-left:25px;
	margin-bottom:15px;
}*/
/*************
/* リスト
*************/
/*************
サイド
*************/
@media screen and (max-width: 640px) {
  #sidebar {
    box-shadow: 0px 6px 9px -7px white;
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    background: url(images/bg_sub.png);
  }
}

.side-title, .side-content_title {
  padding: 10px;
  background: url(images/side_h3.png) #25160E;
  font-family: "Hiragino Mincho Pro", "ヒラギノ明朝 Pro W6", "HGS明朝E", "MS P明朝", serif;
  color: #FFFFFF;
}
@media screen and (min-width: 641px) {
  .side-title, .side-content_title {
    text-align: center;
  }
}

.side_block, .side-login-block, .side_block--min, .side-content_block {
  background: #27140C;
  padding: 10px;
  margin-bottom: 20px;
  color: #FFFFFF;
}

.side_block--inner {
  padding: 10px;
}

.side_block--min {
  background: transparent;
}
.side_block--min dt {
  text-align: center;
  padding: 10px;
  /*		background: #FEF3F7;
  		border-bottom: 1px solid #FE92B3;
  		font-weight: bold;
  		color:#FF004E;*/
}
.side_block--min dd {
  text-align: center;
  padding: 5px;
  font-size: 1.2rem;
  /*		border-left: 1px solid #FEF3F7;
  		border-right: 1px solid #FEF3F7;
  		border-bottom: 1px solid #FEF3F7;*/
}
.side_block--min dd img {
  margin: 5px 0;
}

.side_block--mincard dt {
  background: url(images/side_h4.png) no-repeat top center;
  padding: 0 0 0 20px;
  min-height: 50px;
}

.side-content_title {
  background: url(images/bg_sub.png);
}

.side-content_block {
  background: url(images/bg_sub.png);
}
.side-content_block li {
  border-top: 1px solid #202020;
  border-bottom: 1px solid #000000;
}
.side-content_block a {
  display: block;
  padding: 10px 10px 10px 30px;
  background: url(images/li_01.png) no-repeat 10px center;
  color: #FFFFFF;
}

@media screen and (max-width: 640px) {
  .side_block--banner_area {
    text-align: center;
  }
}

/*******************************
/* フッター
*******************************/
#footer {
  background: url(images/bg_sub.png);
}
@media screen and (min-width: 641px) {
  #footer .wrap {
    width: 1002px;
    padding: 20px;
  }
}

.footer--line {
  background: url(images/footer_line.png) repeat-x center;
  height: 3px;
}

.foothr {
  height: 1px;
  background-color: #F7608B;
  border: none;
  margin-bottom: 2px;
}

.footer_img--title {
  float: right;
  width: 25%;
}

.footer_list_wrap {
  margin-top: 20px;
  padding-left: 10px;
}
@media screen and (max-width: 640px) {
  .footer_list_wrap {
    display: none;
  }
}

.footer_list {
  font-size: 1.2rem;
  width: 190px;
  float: left;
  margin-right: 20px;
}

.footer_list li {
  margin-bottom: 8px;
  padding: 3px 3px 3px 20px;
  border-left: 1px solid #272822;
}

#footerLower {
  clear: both;
  padding: 50px 10px 10px 10px;
  text-align: center;
}

address {
  font-style: normal;
  font-size: 10px;
}

.top_comment {
  width: 435px;
  padding: 20px 250px 20px 20px;
  background: url(images/info_back.png) no-repeat right 50%;
  margin-top: 14px;
  margin-bottom: 30px;
  line-height: 2.0em;
}

/*
 * スケジュール
 */
.day_change {
  text-align: center;
  margin: 10px;
}
.day_change input[type="submit"] {
  padding: 2% 10%;
}
@media screen and (min-width: 641px) {
  .day_change input[type="submit"] {
    padding: 5px 60px;
  }
}

.schedule_list li {
  float: left;
  border: 1px solid #000000;
  box-sizing: border-box;
}
@media screen and (max-width: 640px) {
  .schedule_list li {
    width: 50%;
  }
  .schedule_list li:nth-child(odd) {
    clear: both;
  }
}
@media screen and (min-width: 641px) {
  .schedule_list li {
    display: table;
  }
}

.schedule_list_img,
.schedule_list_txt {
  text-align: center;
  padding: 10px;
  background: url(images/bg_sub--light.png);
}
@media screen and (min-width: 641px) {
  .schedule_list_img,
  .schedule_list_txt {
    width: 170px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 640px) {
  .schedule_list_img {
    border-bottom: none;
  }
}
@media screen and (min-width: 641px) {
  .schedule_list_img {
    border-right: none;
  }
}

.schedule_list_txt {
  vertical-align: middle;
}
.schedule_list_txt a, .schedule_list_txt strong {
  display: block;
}
@media screen and (max-width: 640px) {
  .schedule_list_txt {
    border-top: none;
  }
}
@media screen and (min-width: 641px) {
  .schedule_list_txt {
    display: table-cell;
    box-sizing: border-box;
    border-left: none;
  }
}

/*==============================================================================================

header-nav_button

==============================================================================================*/
.header-nav_btn {
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999;
}
.header-nav_btn .btn {
  background: #000000;
  padding: 5px;
  border-radius: 4px;
}
@media screen and (max-width: 640px) {
  .header-nav_btn .btn {
    background: #D31050;
    color: #FFFFFF;
    padding: 10px;
    text-align: center;
    position: absolute;
    right: 1%;
    z-index: 9999;
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 641px) {
  .header-nav_btn .btn {
    display: none;
  }
}

/*
 * スライド
 */
#carouselWrap {
  margin: 0 auto;
  margin-top: 26px;
  width: 100%;
  height: 410px;
}
@media screen and (max-width: 640px) {
  #carouselWrap {
    display: none;
  }
}

#carouselPrev {
  /*	position:absolute;
  	top:65px;
  	left:-8px;
  	cursor:pointer;*/
  display: none;
}

#carouselNext {
  /*	position:absolute;
  	top:65px;
  	right:-8px;
  	cursor:pointer;*/
  display: none;
}

#carouse {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#carouselInner ul.column {
  width: 1600px;
  height: 410px;
  list-style-type: none;
  float: left;
  margin-right: -5px;
}

#carouselInner ul.column li {
  float: left;
  display: inline;
}

#carouselInner ul.column li img {
  border: none;
}

/*==============================================================================================

mw_wp_form

==============================================================================================*/
.mw_wp_form table {
  line-height: 3rem;
}

.mw_wp_form th, .mw_wp_form td {
  padding: 20px;
}
@media screen and (max-width: 640px) {
  .mw_wp_form th, .mw_wp_form td {
    display: block;
    padding: 10px;
  }
}

.mw_wp_form th {
  background: #0F0F0F;
  color: #FF4A81;
}
@media screen and (min-width: 641px) {
  .mw_wp_form th {
    width: 25%;
  }
}

.mw_wp_form td {
  background: #2B2B2B;
}
@media screen and (min-width: 641px) {
  .mw_wp_form td {
    width: 75%;
  }
}

.mw_wp_form td span {
  display: inline-block;
  margin-right: 20px;
}

.mw_wp_form .submit_btn_area {
  text-align: center;
}
@media screen and (max-width: 640px) {
  .mw_wp_form .submit_btn_area input {
    margin: 5px 0;
  }
}
.mw_wp_form input[type="submit"] {
  background: #FF4A81;
  color: #FFFFFF;
  border-radius: 4px;
  border: none;
  padding: 10px;
  font-size: 1.8rem;
  font-weight: bold;
}
.mw_wp_form input[type="submit"]:hover {
  color: #D2003F;
}

/*////////////////////////////////

home

////////////////////////////////*/
.home #main_nav {
  display: none;
}
.home #footer {
  display: none;
}

.entrance_form {
  text-align: center;
}
.entrance_form p {
  margin: 30px 0;
}
.entrance_form img {
  max-width: 100%;
  width: auto;
  height: auto;
}


