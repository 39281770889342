@charset "utf-8";
/*
Theme Name: aisai_theme
Theme URI: http://b-faith.com/
Description:
Version: 1.0
Author: o2 Group
Author URI: http://b-faith.com/
Tags: simple
*/



/*============================================================================================

@import

============================================================================================*/

//サイトの初期設定
@import 'config.scss';

//サイトのレイアウトを設定
@import 'layout.scss';

//mixinとclassでスタイルと機能を定義
@import 'mixin.scss';



/*============================================================================================

common

============================================================================================*/

*{margin:0;padding:0;}

html{
	background:#000000;
	font-size: $font-size_initialize;
}

body{
	font-family: $font-family;
	font-size: $font-size;
	color: $text_color;
	line-height: $line-height;
	letter-spacing: $letter-spacing;
	word-wrap: break-word;
	overflow-wrap: break-word;
	background: #000000;
}

a{color:$link_color;text-decoration:none;}
a:hover{text-decoration:none;opacity:0.7;}
a:active, a:focus {outline: 0;}
img{border:0;}
.clear{clear:both;}
p{margin-bottom: 10px;}
.center{text-align:center;}
.right{text-align:right;}
li {
	list-style:none;
}
a:hover img {
	opacity:0.7;	
}

input[type="text"],input[type="password"],textarea{
	border: 1px solid #EBEBEB;
	padding: 10px;
	width: 100%;
	box-sizing: border-box;
}


.none{
	display:none;
}

.mobile_none{
	@include mq-mobile{
		display: none;
	}
}

.pc_none{
	@include mq-pc{
		display: none;
	}
}

/*============================================================================================

btn

============================================================================================*/

.btn_wrap{
	text-align: center;
	background: #FF4A81;
	margin: 5px 10px;
	padding: 5px;
	border-radius: 4px;
	cursor: pointer;
	a{
		color: #FFFFFF;
	}
}

.btn_wrap input[type="submit"]{
	display: block;
	color: #FFFFFF;
	border: none;
	background: transparent;
	padding:10px;
	text-align: center;
	width: 100%;
	cursor: pointer;
}


/*============================================================================================

headline

============================================================================================*/

%headline_base{

	font-weight: normal;
	font-family: $font-family-mincyo;
	padding: 20px 10px 10px 40px;

	@include mq-mobile{
		padding-top: 15px;
		border-radius: 4px;
		margin-bottom: 5px;
	}

	@include mq-pc{
		display: table;
		width: 100%;
		height: 60px;
		box-sizing: border-box;
		font-size: 1.8rem;
		padding-top: 10px;
	}

	span{
		@include text_shadow_gray;
		vertical-align: middle;
		display: table-cell;
		color: #FFFFFF;
	}

}

.headline--big,
.headline--large{
	@extend %headline_base;
	background: url(images/h2_bg.png) no-repeat;
}

.headline--medium,
.headline--min{
	@extend .headline--big;
	@include mq-pc{
		background: url(images/side_h3.png);
		display: table;
		width: 100%;
		height: 50px;
	}
}

.headline--small{
	margin-bottom: 10px;
	font-size:120%;
	color: #FF7AB1;
	border-left: 2px solid #FF7AB1;
	border-bottom: 1px dotted #FFD0E4;
	padding: 0 10px 10px 10px;
}




/*******************************
/* ヘッダー
*******************************/
#header{

	position:relative;
	color: #FFFFFF;
	font-weight:normal;
	padding: 5px;

	@include mq-mobile{
		text-align: left;
		padding: 1rem;
	}

	@include mq-pc{
		background: url(images/header_bg.png) top center no-repeat;
	}

	.header_title {
		font-size: 1.4rem;
		padding: 5px;
		margin: 10px 0;
		@include mq-mobile{
			margin-right: 100px;
		}
		@include mq-pc{
			text-align: center;
			margin-bottom: 20px;
		}

	}

}



/*******************************
/* ヘッダーイメージ
*******************************/
.header_img--title{
	text-align: center;
	@include mq-pc{
	}
}

.header_img--copy{
	text-align: center;
	@include mq-pc{
	}	
}

.header_img--data{
	@include mq-pc{
		position:absolute;
		top:190px;
		right:0;
	}
}


/******************
/* ヘッダーナビゲーション
******************/

/************************************
/* メインナビゲーション
************************************/
#main_nav {
	background:url(images/nav_bg_on.png) repeat-x center;
	ul li {
		float:left;
	}
	img{
		vertical-align: bottom;
	}
}







/*==============================================================================================

hentry

==============================================================================================*/

.hentry{

	//投稿画像リサイズ
	.size-thumbnail,
	.size-medium,
	.size-large,
	.size-full{
		max-width: 100%;
		width: auto; /* Prevent stretching of full-size and large-size images with height and width attributes in IE8 */
		height: auto; /* Make sure images with WordPress-added height and width attributes are scaled correctly */
	}

	.aligncenter {
		clear: both;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	@include mq-pc{
	
		.alignleft {
			float: left;
			margin-right: 20px;
			margin-bottom: 20px;
			max-width: 100%;
		}

		.alignright {
			float: right;
			margin-left: 20px;
			margin-bottom: 20px;
			max-width: 100%;
		}

	}

	p,ul,ol,dl,ul,blockquote,table,hr{

		@include mq-pc{
			margin: 0 0 20px 0;
		}

	}


}

.hentry + .hentry{
	margin-top: 40px;
}

.hentry_header{
	margin-bottom: 10px;
}

.hentry_thumbnail{
	@include mq-pc{
		margin-right: 10px;
		float:left;	
	}
}

.page .hentry_thumbnail,
.single .hentry_thumbnail{
	@include mq-pc{
		margin-right: 0;
		float:none;	
	}
}

.hentry{

	* {

		+ h1,+ h2{
			margin-top: 40px;
		}

		+ h3,+ h4,+ h5,+ h6{
			margin-top: 20px;
		}

	}

	h2{
		@include text_shadow_black;
		background: url("images/bg_sub--light.png");
		font-weight: normal;
		padding: 10px;
		@include mq-mobile{
			margin-bottom: 10px;
		}
		@include mq-pc{
			padding: 30px 40px;		
		}
	}

	h2 + .min-content{
		@include mq-pc{
			border-left: 1px solid #34322B;
			border-right: 1px solid #34322B;
			border-bottom: 1px solid #34322B;
			padding: 40px 40px 20px 40px;
			margin-bottom: 50px;			
		}
	}

	.min-content--pad{
		padding: 0 !important;
		border: none !important;
	}

	dl.panel{

		font-size: 1.8rem;
		margin-bottom: 10px;

		@include mq-mobile{
			border-radius: 4px;
		}

		dt,dd{
			text-align: center;
		}

		dt{
			background: #FFFFFF;
			color: #FF4A81;
		}
		
		dd{
			background: #FF4A81;
			color: #FFFFFF;
		}

		@include mq-pc{

			@include clearfix_mix;

			dt,dd{
				width: 24%;
				float: left;
				box-sizing: border-box;
				margin-bottom: 1%;
				padding: 10px;
				display: inline-block;
			}

			dt{
				font-size: 1.6rem;
				//border-radius: 8px 0 0 8px;
				border-right: none;
			}

			dd{
				font-size: 1.6rem;
				//border-radius: 0 8px 8px 0;
				border-left: none;
				margin-right: 1%;
			}			

		}
	}

	ul.panel{

		@include clearfix_mix;

		li{
			text-align: center;
			background: url(images/bg_ptn.png);
			border-radius: 4px;
			padding: 2%;
			color: #EF1693;
			width: 50%;
			float: left;
			margin-bottom: 1%;
			box-sizing: border-box;
		}

		li:nth-child(odd){
			width:49%;
			margin-right: 1%;
		}
		li:nth-child(even){
			width:49%;
			margin-left: 1%;
		}

		@include mq-pc{
			font-size: 2rem;
		}

	}

}

//.hentryの中の見出し要素用
.hentry .tittle--big{
	font-size: 1.8rem;
	margin-bottom: 20px;
	color: #F42B95;
	border-left: 2px solid #F42B95;
	padding-left: 10px;
}

//.hentryの中の見出し要素用
.hentry .btn--link{

 	@extend .tittle--big;
 	@include text_shadow_gray;
 	text-align: center;
	font-size: 2.2rem;
	background: #FF3788;
	border: none;
	border-radius: 8px;
	color: #FFFFFF;
	padding:10px;

	@include mq-pc{
		margin: 20px 20% 20px 20% !important;	
	}

	a{
		display: block;
		color: #FFFFFF;		
	}
}

.hentry strong{
	font-size: 140%;
	line-height: 1.6;
	color: #D60C76;
}

.hentry .strong_wrap{
	background: url(images/bg_ptn--stripe.png) #F4F4F2;
	padding:20px;
	margin-bottom: 20px;
	border-radius: 6px;
	strong{
		display: block;
		text-align: center;
	}
}

.hentry .member_qr,
.hentry .login_qr{

	@include clearfix_mix;
	border: 1px solid #FEE4ED;
	padding: 20px;
	margin-bottom: 10px;
	background: url("images/bg_ptn--stripe.png") #E8E8E8;
	@include mq-pc{
		display: table;		
	}

	.member_qr--title,
	.login_qr--title{
		@include text_shadow_white;
		text-align: center;
		padding: 10px;
		font-size: 2rem;
		margin-bottom: 5px;
		display: block;
		background: rgba(255,255,255,0.5);
		@include mq-pc{
			font-size: 2.6rem;
		}
	}

	.member_qr--title{
		border: 5px solid #62B39A;
		color:#62B39A;
	}

	.login_qr--title{
		border: 5px solid #45C5CF;
		color: #45C5CF;
	}

	dt{
		color: #341617;
		@include mq-pc{
			display: table-cell;
			width: 60%;
		}

	}

	dd{
		text-align: center;
		@include mq-pc{
			display: table-cell;
			width: 40%;
			vertical-align: middle;
			img{
				margin: 0 0 0 10px !important;
			}
		}
	}

}

.hentry .caution--list{
	li{
		font-size: 120%;
		margin-bottom: 10px;
		padding: 10px;
	}

	li:nth-child(even){
		background: #262626;
	}
}

.hentry .pickup{
	@include text_shadow_white;
	text-align: center;
	background: url(images/bg_ptn.png);
	border-radius: 4px;
	padding: 2%;
	color: #EF1693;
	margin-bottom: 1%;
	box-sizing: border-box;
	font-size: 120%;
}

.hentry .new_cast{
	background: #F7558D;
	color: #FFFFFF;
	padding: 2px 10px;
	border-radius: 8px;
}

.hentry .now_attend{
	@extend .new_cast;
	background: #D650D6;
}




/*******************************
メイン画像
*******************************/

/*******************************
コンテンツ位置
*******************************/
#container{
	margin-top: 40px;
}

#main {
//	float: left;
//	width: 715px;
//	padding-bottom:40px;
	@include mq-mobile{
		padding: 1%;
	}
}





/*************************
/* topページ
*************************/
.top_content {
	margin:5px auto 30px auto;	
}

%base_block{
	margin-top: 40px;
	margin-bottom:40px;
}

.base_block {
	@extend %base_block;
}

.cast_content {
	@extend %base_block;
}

.blog_list {
	margin-bottom:30px;
}
.search_form {
	width:705px;
	padding:5px 5px 5px 6px;
	margin-top:14px;
	margin-bottom:30px;
}

.a_cast {

	float:left;

	@include mq-mobile{
		width: 48%;
		margin: 1%;
		text-align: center;	
		&:nth-child(odd){
			clear: both;
		}
	}

	@include mq-pc{
		width:200px;	
		margin:15px;
		&:nth-child(3n+1){
			clear: both;
		}
	}

}

.a_cast_fig {
//	overflow:hidden;
//	width:220px;
//	height:330px;
}

.a_cast_fig img {
//	display:block;
	@include fluid_img_mix;
}

.a_cast_comment {
	text-align:center;
	position:relative;
}

.like_list {
	@include mq-pc{
		position:absolute;
		z-index:10;
		top:-100px;
		left:80px;		
	}
}

.like_list li a {
	display:block;
	background:url(images/iine_comment.png) no-repeat 0 50% #FF4A81;
	margin-bottom:3px;
	color:#FFFFFF;
	border-radius: 4px;
	border: 1px solid #FFFFFF;
	padding-left:35px;
	@include mq-pc{
		line-height:28px;
		height:28px;
		width:93px;
		text-align:left;
		font-size:0.8em;		
	}
}

.blog_list{
	@extend %base_block;
}

.blog_list ul{
	margin: 5px;
}

.blog_list ul li {
	padding-bottom: 10px;
	margin-bottom:15px;
	border-bottom: 1px dotted #CCCCCC;
}

.blog_list ul li:last-child {
	border:none;
}

.blog_list ul li a {
	display: block;
	margin-bottom: 8px;
	font-size: 1.8rem;
}

.blog_list ul li a:hover {
	text-decoration:underline;	
}



/*************************
/* スケジュールページ
*************************/
.like_list2 {
//	width:675px;
//	margin:0 auto;
//	min-height:100px;
}

.like_list2 li{
/*	display:block;
	background:url(images/iine_comment.png) no-repeat 0 50%;
	padding-left:35px;
	line-height:28px;
	height:28px;
	width:93px;
	text-align:left;
	font-size:0.8em;
	margin-bottom:3px;
	margin-right:6px;
	color:#630;
	float:left;
	overflow:hidden;*/
	background: #FF4A81;
	padding: 5px;
	box-sizing: border-box;
	float:left;
	border-radius: 12px;
	text-align: center;

	@include mq-mobile{
		width: 48%;
		margin: 1%;		
	}

	@include mq-pc{
		width: 23%;
		margin: 1%;
	}

	a{
		color: #FFFFFF;
		display: block;
		font-size: 90%;
	}

}

.like_list2 li:nth-child(4n+1) {
	@include mq-pc{
		clear: both;
	}
}

/*************************
/* 奥様たちページ
*************************/
.a_cast2 {
//	width:165px;
//	height:280px;
	float:left;
	margin:5px 5px 20px 5px;
}
.a_cast_fig2 {
	overflow:hidden;
	width:165px;
	height:248px;
}
.a_cast_fig2 img {
	display:block;	
}
.a_cast_comment2 {
	text-align:center;
	position:relative;
}

.like_list3 {
	position:absolute;
	z-index:10;
	top:-93px;
	left:50px;
}

.like_list3 li a {
	display:block;
	background:url(images/iine_comment.png) no-repeat 0 50%;
	padding-left:35px;
	line-height:28px;
	height:28px;
	width:93px;
	text-align:left;
	font-size:0.8em;
	margin-bottom:3px;
	color:#630;
}
.a_cast_comment2 img {
	padding-top:4px;	
}
/*************************
/* single-cast.php
*************************/


/*============================================================================================

cast

============================================================================================*/

#cast_fig {
	@include mq-pc{
		float:right;
		width: 35%;
	}
}

#main_fig {
	@include mq-mobile{
		text-align: center;
	}

	img{
		border: 1px solid #FF7AB1;
	}
}

.cast_txt{
	@include mq-pc{
		float:left;
		width: 60%;		
	}
}

#mini_thm ul li {
	overflow:hidden;
	width:70px;
	height:90px;
	margin:5px 5px 5px 0px;
	float:left;
}

.cast_profile--name{

	font-size: 3rem;
	color: #FF4A81;
	border-bottom: 2px dotted #A1A1A1;
	margin-bottom: 10px;

	span{
		font-size: 70%;
	}

	@include mq-mobile{
		text-align: center;
	}

}

.cast_profile_tl{

	width: 100%;

	th,td{
		margin-bottom: 5px;
		line-height: 1.8;
		padding: 8px;
	}
	th{
		color: #FF4A81;
		text-align: center;
		background: #0F0F0F;
		font-weight: normal;
		clear:both;
	}

	td{
		background: #2B2B2B;
	}

	@include mq-pc{

	}
	
}

/*.font_min{
	font-size: 90%;
	letter-spacing: 0.1em;
}*/

/*.like_list4 {
	position:absolute;
	z-index:10;
	top:45px;
	left:-138px;
}*/

/*.like_list4 li a {
	display:block;
	background:url(images/iine_comment.png) no-repeat 0 50%;
	padding-left:35px;
	line-height:28px;
	height:28px;
	width:93px;
	text-align:left;
	font-size:0.8em;
	margin-bottom:3px;
	color:#630;
}*/

#cast_profile02 {
//	width:450px;
//	margin:5px auto;
}

#cast_profile02 dl dt {
/*	width:100px;
	height:20px;
	border-radius:10px;
	border:1px #CCC solid;
	line-height:20px;
	text-align:center;*/
}

#cast_profile02 dl dd {
/*	width:200px;
	margin-left:100px;
	margin-top:-20px;
	border-bottom:1px #CCC dotted;
	padding-left:20px;
	margin-bottom:5px;*/
}

#enable_play {

	margin-bottom: 20px;

	dl{
	
		margin-bottom: 2px;
		box-sizing: border-box;

		@include mq-mobile{
			@include clearfix_mix;
		}

		&:nth-child(even){
			color: #EA704D;
			@include mq-pc{
				border-left: 1px solid #000000;
			}			
		}

		&:nth-child(odd){
			color: #18B8BC;
			@include mq-pc{
				border-right: 1px solid #000000;			
			}
		}

		dt,dd{
			padding:5px;
			box-sizing: border-box;
		}

		dt{
			float: left;
			width: 75%;
			text-align: center;
			background: #0F0F0F;
		}
		dd{
			float: left;
			width: 25%;
			text-align: center;
			background: #2B2B2B;
		}

	}

	@include mq-pc{
		dl{
			width: 50%;
			float:left;
			dd{
				width: 25%;
			}
		}
	}

}

.play_key {
//	width:80px;
//	background:#DBD2C8;
}

.play_value {
//	width:25px;
//	background:#FFF;
	
}

//.enable_play_index {
/*	width:420px;
	height:25px;
	padding-left:10px;
	border-bottom:1px #630 solid;
	border-left:10px #630 solid;
	line-height:25px;*/
//	@extend .headline--small;
//}

.cast_profile_comment{
//	border-radius: 8px;
	border: 3px solid #FF7AB1;
	background: url("images/bg_ptn.png");
	margin-bottom: 20px;
}

.cast_profile_comment--title{
	background: #FF7AB1;
	color: #FFFFFF;
	padding:10px;
	font-size: 2rem;
}

.cast_profile_comment--body{
	@include text_shadow_white;
	padding:20px;
	color: #FF7AB1;
}

.tencyo_comment{
	border: 1px solid #34322B;;
	padding: 20px;
	margin-bottom: 20px;
}

.shopstaff_comment img {
	padding-right:10px;
	float:left;
	display:block;	
}
.shopstaff_comment {
/*	width:640px;
	padding:25px;
	border:1px #FFF solid;
	border-radius:12px;
	box-shadow:#D2C797 5px 5px 10px;
	margin:10px auto;
	min-height:62px;*/
}


/*************************
/* page-blog.php
*************************/

/*============================================================================================

blog

============================================================================================*/

%blog_tpl{
	padding: 20px;
	border: 1px solid #34322B;
	@include mq-pc{
		padding: 20px 40px;
	}
}

.blog_title:first-child{
	margin-top: 60px;
}

.blog_title {
	@extend %blog_tpl;
	@include text_shadow_white;
	position: relative;
	background: url("images/bg_ptn--stripe.png") #E0E0E0;
	border-bottom: none;
	color: #FF4A81;
	font-size: 2.2rem;
}

.blog_title--date{
	background: #FF4A81;
	color: #FFFFFF;
	position: absolute;
	top: -20px;
	right: 10px;
	font-size: 1.4rem;
	padding: 5px;
	line-height: 1.4;
	text-shadow: none;
	border-radius: 4px;
}

.blog_content {
	@extend %blog_tpl;
	border-top: none;
	margin-bottom: 50px;
}


.paginate_div {
	text-align:center;	
}


/*************************
/* page.php
*************************/
.page_title {
	width:696px;
	height:38px;
	line-height:38px;
	padding-left:20px;
	margin-bottom:15px;
	text-indent:-9999px;
}
#index_system {
	background:url(images/index_system.png) no-repeat 0 50%;
}
#index_magazine {
	background:url(images/index_magazine.png) no-repeat 0 50%;
}
#index_recruit {
	background:url(images/index_recruit.png) no-repeat 0 50%;
}
#index_inquiry {
	background:url(images/index_inquiry.png) no-repeat 0 50%;
}
#index_caution {
	background:url(images/index_caution.png) no-repeat 0 50%;
}
#index_feature {
	background:url(images/index_feature.png) no-repeat 0 50%;
}
#index_404 {
	background:url(images/index_404.png) no-repeat 0 50%;
}

.page_div2 {
/*	width:685px;
	padding:14px;
	background:#fff1d7;
	border:1px solid #e6d7a8;
	margin-top:10px;
	margin-bottom:30px;
	line-height:1.8em;
	background:#fcfff4 url(images/div_back.png) no-repeat 0 50% !important;*/
}

#page_tmp_content{
	padding: 20px 0;
}

#page_tmp_content .caution {
	width:600px;
	margin:20px auto;
	display:block;
}

#recruit_child_link {
	width:710px !important;
	margin:0 auto 0px auto !important;
	height: 45px;
}
#recruit_child_link li {
	background:none !important;
	padding:0 !important;
	overflow:hidden;	
	float:left;
	
}
#recruit_child_link li a {
	width:140px !important;
	background:url(images/recruit_icon.png)  #ECE8D2 left 50% no-repeat !important;
	display:block;
	border-radius:6px;
	border:1px #FFF solid;
	color:#4F0000;
	font-weight:bold;
	text-shadow:#D5CC99 1px 1px;
	text-align:center;
	height:45px;
	line-height:45px;
}

.cast_gravure ul li {
	overflow:hidden;
	float:left;
	width:120px;
	height:155px;
	border-radius:6px;
	border:2px #FFF solid;
	margin:5px;
}


/*************************
/* その他
*************************/

#date {
	text-align: right;
	padding-right: 5px;
	padding-top: 15px;
	padding-bottom: 10px;
	font-weight:bold;
	color:#858585;
	margin-top:-50px;
}
#date2 {
	text-align: right;
/*	padding-right: 5px;
	padding-top: 15px;
	padding-bottom: 10px;
	font-weight:bold;
	color:#858585;
	margin-top:-40px;*/
}

#next {
	text-align: center;
	padding-top: 30px;
	padding-bottom: 30px;
}

img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
img.alignright {
    padding: 4px;
    margin: 0 0 2px 7px;
    display: inline;
}
img.alignleft {
    padding: 4px;
    margin: 0 7px 2px 0;
    display: inline;
}
.alignright {
    float: right;
}
.alignleft {
    float: left;
}

.box_img_border{
   border: 1px solid #cccccc;
   padding: 1px;
  margin: 1px;
}


/*************
/* 見出し
*************/
/*.indextype01 {
	width:696px;
	height:38px;
	line-height:38px;
	background:url(images/index_back01.png) 0 50% no-repeat;
	font-size: 1.3em;
	color: #6a3906;
	font-family: "ヒラギノ明朝 Pro W6", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", serif;
	padding-left:25px;
	margin-bottom:15px;
}*/

/*************
/* リスト
*************/

/*************
サイド
*************/

#sidebar {
	@include mq-mobile{
		@include box_shadow_white;
		display:none;
		position: absolute;
		top: 0;
		width: 100%;
		background: url(images/bg_sub.png);	
	}
}

.side-title{
	padding: 10px;
	background: url(images/side_h3.png) #25160E;
	font-family: $font-family-mincyo;
	color: #FFFFFF;
	@include mq-pc{
		text-align: center;
	}
}

.side_block{
	background:#27140C;
	padding:10px;
	margin-bottom:20px;
	color: #FFFFFF;	
}

.side-login-block{
	@extend .side_block;
	//line-height: 2;
}

.side_block--inner{
	padding: 10px;
//	margin: 5px;
//	border: 5px solid #FFFFFF;
//	background: #FFFFF0;
}

.side_block--min{

	@extend .side_block;
	background: transparent;

	dt{
		text-align: center;
		padding: 10px;
/*		background: #FEF3F7;
		border-bottom: 1px solid #FE92B3;
		font-weight: bold;
		color:#FF004E;*/
	}

	dd{
		text-align: center;
		padding:5px;
		font-size: 1.2rem;
/*		border-left: 1px solid #FEF3F7;
		border-right: 1px solid #FEF3F7;
		border-bottom: 1px solid #FEF3F7;*/
		img{
			margin: 5px 0;
		}
	}

}

.side_block--mincard{
	dt{
		background: url(images/side_h4.png) no-repeat top center;
		padding: 0 0 0 20px;
		min-height: 50px;
	}
}

.side-content_title{
	@extend .side-title;
	background: url(images/bg_sub.png);
}

.side-content_block{
	@extend .side_block;
	background: url(images/bg_sub.png);	

	li{
		border-top: 1px solid #202020;
		border-bottom: 1px solid #000000;
	}

	a{
		display: block;
		padding: 10px 10px 10px 30px;
		background: url(images/li_01.png) no-repeat 10px center;
		color: #FFFFFF;
	}
}

.side_block--banner_area{
	@include mq-mobile{
		text-align: center;
	}
}



/*******************************
/* フッター
*******************************/


#footer{
	background: url(images/bg_sub.png);
	.wrap{
		@include mq-pc{
			width: 1002px;
			padding: 20px;
		}
	}
}

.footer--line{
	background: url(images/footer_line.png) repeat-x center;
	height: 3px;
}

.foothr{
	height: 1px;
	background-color: #F7608B;
	border: none;
	margin-bottom: 2px;
}

.footer_img--title{
	float: right;
	width: 25%;
}

.footer_list_wrap{
	margin-top: 20px;
	padding-left: 10px;
	@include mq-mobile{
		display:none;
	}
}

.footer_list{
	font-size: 1.2rem;
	width:190px;
	float:left;
	margin-right:20px;	
}

.footer_list li{
	margin-bottom: 8px;
	padding: 3px 3px 3px 20px;
	border-left: 1px solid #272822;
}

#footerLower{
	clear:both;
	padding: 50px 10px 10px 10px;
	text-align:center;
}

address{
	font-style:normal;
	font-size:10px;
}


.top_comment {
	width:435px;
	padding:20px 250px 20px 20px;
	background:url(images/info_back.png) no-repeat right 50%;
	margin-top:14px;
	margin-bottom:30px;
	line-height:2.0em;
}

/*
 * スケジュール
 */

.day_change{

	text-align: center;
	margin:10px;

	input[type="submit"]{
		padding: 2% 10%;
	}

	@include mq-pc{
		input[type="submit"]{
			padding: 5px 60px;
		}		
	}
}

.schedule_list{

	li{
	
		float: left;
		border: 1px solid #000000;
		box-sizing: border-box;

		@include mq-mobile{
			width: 50%;
			&:nth-child(odd){
				clear:both;		
			}
		}

		@include mq-pc{
			display: table;		
		}

	}

}

.schedule_list_img,
.schedule_list_txt{

	text-align: center;
	padding: 10px;
//	border: 1px solid #FFFFFF;
	background: url(images/bg_sub--light.png);

	@include mq-mobile{
	//	float: left;
	}

	@include mq-pc{
		width: 170px;
		box-sizing: border-box;
	}

}

.schedule_list_img{
//	background: #FFFFDA;
//	img{
//		@include fluid_img_mix;
//	}
	@include mq-mobile{
		border-bottom: none;
	}
	@include mq-pc{
		border-right: none;
	}
}

.schedule_list_txt{
//	background: #FFFFF0;
	vertical-align: middle;
	a,strong{
		display:block;
	}
	@include mq-mobile{
		border-top: none;
	}
	@include mq-pc{
		display: table-cell;
		box-sizing: border-box;	
		border-left: none;

	}
}



/*==============================================================================================

header-nav_button

==============================================================================================*/

.header-nav_btn{

	text-align: center;
	top: 0;
	width: 100%;
	z-index: 999;

	.btn{
		@include button;

		@include mq-mobile{
			background: #D31050;
			color: #FFFFFF;
			padding: 10px;
			text-align: center;
			position: absolute;
			right: 1%;
			z-index: 9999;
			border-radius: 0 0 4px 4px;
			cursor: pointer;
			font-size: 1.2rem;
		}

		@include mq-pc{
	 		display: none;
		}	

	}

}



/*
 * スライド
 */

#carouselWrap{
	margin: 0 auto;
	margin-top: 26px;
	width:100%;
	height:410px;
//	position:absolute;
//	top: 26px;
	@include mq-mobile{
		display: none;
	}
}

#carouselPrev{
/*	position:absolute;
	top:65px;
	left:-8px;
	cursor:pointer;*/
	display:none;
}
#carouselNext{
/*	position:absolute;
	top:65px;
	right:-8px;
	cursor:pointer;*/
	display:none;
}
#carouse{
	width:100%;
	height:100%;
	overflow:hidden;
}

#carouselInner ul.column{
	width:1600px;
	height:410px;
//	padding:15px 0 15px 15px;
	list-style-type:none;
	float:left;
	margin-right: -5px;
}
#carouselInner ul.column li{
	float:left;
//	margin-right:10px;
	display:inline;
}
#carouselInner ul.column li img{
	border:none;
}

/*==============================================================================================

mw_wp_form

==============================================================================================*/

.mw_wp_form table{
	line-height: 3rem;
}

.mw_wp_form{
	th,td{
		padding: 20px;
		@include mq-mobile{
			display: block;
			padding: 10px;
		}
	}
}

.mw_wp_form th{
	background: #0F0F0F;
	color: #FF4A81;
	@include mq-pc{
		width: 25%;
	}
}

.mw_wp_form td{
	background: #2B2B2B;
	@include mq-pc{
		width: 75%;
	}
}

.mw_wp_form{
	td span{
		display: inline-block;
		margin-right: 20px;
	}
}

.mw_wp_form{
	.submit_btn_area{
		text-align: center;
		input{
			@include mq-mobile{
				margin: 5px 0;
			}			
		}
	}

	input[type="submit"]{
		background:#FF4A81;
		color: #FFFFFF;
		border-radius: 4px;
		border: none;
		padding: 10px;
		font-size: 1.8rem;
		font-weight: bold;
	}

	input[type="submit"]:hover{
		color: #D2003F;
	}

}

/*////////////////////////////////

home

////////////////////////////////*/

.home{
	#main_nav{
		display: none;
	}

	#footer{
		display:none;
	}
}

.entrance_form {
	text-align: center;

	p{
		margin: 30px 0;
	}

	img{
		@include fluid_img_mix;
	}

}
